'use client';

import { FC, useState } from 'react';
import { Box } from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';
import CloseIcon from 'modules/theme/components/icons/basic/Close.svg';
import { Content } from 'components/notificationCenter/partials/content/Content';
import { INotification } from 'modules/stores/notificationCenter/useNotificationCenterStore';

interface Props {
    notification: INotification;
    close: () => void;
}

export const NotificationTopBar: FC<Props> = ({ notification, close }) => {
    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
        close();
    };

    if (!open) {
        return null;
    }

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={1}
            p={1.5}
            bgcolor={(theme) => theme.palette.backgroundTertiaryElevated.main}
        >
            <Svg component={notification.icon} width={24} />
            <Content html={notification.text} />
            {notification.canBeClosed && (
                <Box
                    onClick={handleClose}
                    mt={0.75}
                    ml={2}
                    sx={{
                        cursor: 'pointer',
                    }}
                >
                    <Svg icon component={CloseIcon} width={20} />
                </Box>
            )}
        </Box>
    );
};
