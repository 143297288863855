'use client';

import { FC, useEffect } from 'react';
import { useSearchParams } from 'next/navigation';
import { useSearchAgentAuthentication } from 'components/searchAgent/hooks/useSearchAgentAuthentication';

export const AuthenticateSearchAgent: FC = () => {
    const params = useSearchParams();
    const { setReporterId, loginModal } = useSearchAgentAuthentication();

    useEffect(() => {
        const reporterId = params?.get('reporter') && parseInt(params.get('reporter'));

        if (reporterId) {
            setReporterId(reporterId);
        }
    }, [setReporterId, params]);

    return (
        <>{loginModal}</>
    );
};
