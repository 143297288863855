import { useMemo } from 'react';
import IAnalytics, {
    IGtm,
    IGemius,
    IHotjar,
    IRtbHouse,
    ILivemonitor,
    IFacebookPixel,
    IGemiusPageView,
    IGtmEvent,
    ILivemonitorEvent,
    IRtbHouseEvent,
} from 'modules/analytics/IAnalytics';
import { useAnalyticsStore } from 'modules/stores/analytics/useAnalyticsStore';

const useAnalytics = (): IAnalytics => {
    const setGtmTracker = useAnalyticsStore((store) => store.setGtmTracker);
    const gtmTracker = useAnalyticsStore((store) => store.analyticsGtmTrackerState);

    const setRtbHouseTracker = useAnalyticsStore((store) => store.setRtbHouseTracker);
    const rtbHouseTracker = useAnalyticsStore((store) => store.analyticsRtbHouseTrackerState);

    const setLivemonitorTracker = useAnalyticsStore((store) => store.setLivemonitorTracker);
    const livemonitorTracker = useAnalyticsStore((store) => store.analyticsLivemonitorTrackerState);

    const setFacebookPixelTracker = useAnalyticsStore((store) => store.setFacebookPixelTracker);
    const facebookPixelTracker = useAnalyticsStore((store) => store.analyticsFacebookPixelTrackerState);

    const setGemiusTracker = useAnalyticsStore((store) => store.setGemiusTracker);
    const gemiusTracker = useAnalyticsStore((store) => store.analyticsGemiusTrackerState);

    const gtm: IGtm = useMemo(() => ({
        set: setGtmTracker,
        sendEvent: (...events: IGtmEvent[]): void => {
            if (!gtmTracker) return;

            for (const gtmEvent of events) {
                gtmTracker.sendEvent(gtmEvent);
            }
        },
    }), [
        gtmTracker,
        setGtmTracker,
    ]);

    const facebookPixel: IFacebookPixel = useMemo(() => ({
        set: setFacebookPixelTracker,
        sendEvent: (params: unknown[]): void => {
            if (!facebookPixelTracker) return;

            facebookPixelTracker.sendEvent(params);
        },
        pageView: (): void => {
            if (!facebookPixelTracker) return;

            facebookPixelTracker.pageView();
        },
    }), [
        facebookPixelTracker,
        setFacebookPixelTracker,
    ]);

    const rtbHouse: IRtbHouse = useMemo(() => ({
        set: setRtbHouseTracker,
        sendEvent: (event: IRtbHouseEvent): void => {
            if (!rtbHouseTracker) return;

            rtbHouseTracker.sendEvent(event);
        },
    }), [
        rtbHouseTracker,
        setRtbHouseTracker,
    ]);

    const livemonitor: ILivemonitor = useMemo(() => ({
        set: setLivemonitorTracker,
        sendEvent: (event: ILivemonitorEvent): void => {
            if (!livemonitorTracker) return;

            livemonitorTracker.sendEvent(event);
        },
    }), [
        livemonitorTracker,
        setLivemonitorTracker,
    ]);

    const gemius: IGemius = useMemo(() => ({
        set: setGemiusTracker,
        pageView: (pageView: IGemiusPageView): void => {
            if (!gemiusTracker) return;

            gemiusTracker.pageView(pageView);
        },
    }), [
        gemiusTracker,
        setGemiusTracker,
    ]);

    const hotjar: IHotjar = useMemo(() => ({
        sendEvent: (event: string): void => {
            const hj = () => {
                // @ts-ignore
                if (!window.hj) return;

                // @ts-ignore
                window.hj('event', event);
            };

            hj();
        },
    }), []);

    return {
        gtm,
        hotjar,
        gemius,
        rtbHouse,
        livemonitor,
        facebookPixel,
    };
};

export default useAnalytics;
