import {FC} from 'react';
import {Grid} from '@mui/material';
import useSession from 'modules/stores/session/useSession';
import FooterLink from 'components/layout/footer/components/links/textLink/Link';
import {AppConfigService} from 'modules/appConfig/AppConfigService';
import { principlesOfProtection } from 'modules/common/links/principlesOfProtection/principlesOfProtection';
import { advertisingConditions } from 'modules/common/links/advertisingConditions/advertisingConditions';
import { cookiesConsent } from 'modules/common/links/cookiesConditions/cookiesConditions';
import { googlePersonalDataToRealEstateConditions } from 'modules/common/links/googlePersonalDataToRealEstateConditions/googlePersonalDataToRealEstateConditions';
import { fbPersonalDataToRealEstateConditions } from 'modules/common/links/fbPersonalDataToRealEstateConditions/fbPersonalDataToRealEstateConditions';

export const TextLinks: FC = () => {
    const {session} = useSession();


    const links = [
        {
            name: 'Ako inzerovať',
            path: `${AppConfigService.getNehnutelnostiUrl()}/ako-inzerovat`
        },
        {
            name: 'Podmienky inzercie',
            path: advertisingConditions
        },
        {
            name: 'Firemná inzercia',
            path: `${AppConfigService.getNehnutelnostiUrl()}/nase-sluzby/inzercia`
        },
        {
            name: 'Zvýhodnené inzeráty',
            path: `${AppConfigService.getNehnutelnostiUrl()}/zvyhodnenie-inzeratu`
        },
        {
            name: 'Reklama',
            path: `${AppConfigService.getNehnutelnostiUrl()}/reklama`
        },
        {
            name: 'GDPR',
            path: principlesOfProtection
        },
        {
            name: 'Nastavenie súkromia',
            onClick: () => {
                const w: any = window;// eslint-disable-line

                if (
                    AppConfigService.getEnvironment() === 'production'
                    && typeof w._sp_ !== 'undefined'
                    && typeof w._sp_.gdpr !== 'undefined'
                    && typeof w._sp_.gdpr.loadPrivacyManagerModal !== 'undefined'
                ) {
                    w._sp_.gdpr.loadPrivacyManagerModal(AppConfigService.getSpPrivacyManagerId());
                }
            }
        },
        {
            name: 'Ochrana osobných údajov',
            children: [
                {
                    name: 'Cookies',
                    path: cookiesConsent
                },
                {
                    name: 'Export osobných údajov',
                    path: session ? session.links.gdprExport : `${AppConfigService.getNehnutelnostiUrl()}/gdpr-claim/export`
                },
                {
                    name: 'Vymazanie osobných údajov',
                    path: session ? session.links.gdprAnonymize : `${AppConfigService.getNehnutelnostiUrl()}/gdpr-claim/anonymize`
                },
                {
                    name: 'Oslovovanie marketingovými ponukami',
                    path: fbPersonalDataToRealEstateConditions
                },
                {
                    name: 'Oslovovanie marketingovými ponukami - web',
                    path: googlePersonalDataToRealEstateConditions
                }
            ]
        },
        {
            name: 'O nás',
            path: `${AppConfigService.getNehnutelnostiUrl()}/o-nas`
        },

    ];

    return <Grid container spacing={2}>
        {links.map(({name, path, onClick, children}) => {
            const key = name + path;

            if (!children) return <FooterLink key={key} name={name} path={path} onClick={onClick} inverseColor/>;

            return <FooterLink key={key} name={name} path={path} onClick={onClick} inverseColor>
                {children.map(({name, path}) =>
                    <FooterLink
                        name={name}
                        path={path}
                        key={name + path}
                        showBullet={false}
                    />
                )}
            </FooterLink>;
        })}
    </Grid>;
};
