import { FC, MouseEventHandler, ReactNode, Children } from 'react';
import Link from 'components/link/Link';
import {Text} from 'modules/theme/components/text/Text';
import { Grid, Badge, Theme, Box } from '@mui/material';
import type { SvgIconComponent } from '@mui/icons-material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useOpenMenuState from 'modules/stores/layout/useOpenMenuState';

interface IProps {
    href?: string;
    text: string;
    children?: ReactNode;
    Icon?: SvgIconComponent;
    badgeCount?: number;
    isSubmenu?: boolean;
    openInNewTab?: boolean;
}

const MenuItem: FC<IProps> = ({ href, text, Icon, badgeCount, isSubmenu, openInNewTab, children }) => {
    const submenuKey = `header-mobile-submenu-item-${text}`;

    const {
        open: openSubmenu,
        close: closeSubmenu,
        isOpen: isSubmenuOpen
    } = useOpenMenuState();

    const handleOnClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
        if (!href) {
            event.preventDefault();
        }

        if (children) {
            if (isSubmenuOpen(submenuKey)) {
                closeSubmenu(submenuKey);
            } else {
                openSubmenu(submenuKey);
            }
        }
    };

    return <>
        <Grid container>
            <Grid item xs={12}>
                <Link
                    href={href || '#'}
                    sx={{
                        display: 'block',
                        padding: isSubmenu ? 1.5 : 2
                    }}
                    openInNewTab={openInNewTab}
                    onClick={handleOnClick}
                    noUnderline
                >
                    <Grid container alignItems="center">
                        <Grid item xs={10}>
                            <Text variant="body1">{text}</Text>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{textAlign: 'right'}}>
                                <Badge badgeContent={badgeCount} sx={{
                                    '& .MuiBadge-badge': {
                                        padding: 0,
                                        fontSize: '8px',
                                        textAlign: 'center',
                                        width: (theme: Theme) => theme.spacing(1.75),
                                        height: (theme: Theme) => theme.spacing(1.75),
                                        minWidth: (theme: Theme) => theme.spacing(1.75),
                                        color: (theme: Theme) => theme.palette.labelPrimary.main,
                                        border: (theme: Theme) => '1px solid ' + theme.palette.primary.main,
                                        backgroundColor: (theme: Theme) => theme.palette.backgroundPrimary.main,
                                    }
                                }} showZero>
                                    {Icon && (
                                        <Text variant="body1" component="span">
                                            <Icon sx={{
                                                fontSize: 'inherit',
                                                color: 'primary.main'
                                            }} />
                                        </Text>
                                    )}
                                </Badge>
                                {children && (
                                    <Text variant="body1" component="span">
                                        {
                                            isSubmenuOpen(submenuKey)
                                                ? <ExpandLessIcon sx={{fontSize: 'inherit'}} />
                                                : <ExpandMoreIcon sx={{fontSize: 'inherit'}} />
                                        }
                                    </Text>)
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </Link>
            </Grid>
            <Grid item xs={12}>
                {children && isSubmenuOpen(submenuKey) && (
                    <Grid item xs={12}>
                        <Box sx={{
                            marginBottom: 1,
                            marginLeft: 2,
                            marginRight: 2
                        }}>
                            <Grid container>
                                {Children.map(children, child => (
                                    <Grid item xs={12}>{child}</Grid>
                                ))}
                            </Grid>
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Grid>
    </>;
};

export default MenuItem;
