import { create } from 'zustand';
import {ELoginPosition} from 'modules/gtmEvents/enums/login/ELoginPosition';

type LoginStore = {
    isUserLoggedIn: boolean;
    setIsUserLoggedIn: (flag: boolean) => void;

    loginPosition: ELoginPosition;
    setLoginPosition: (position: ELoginPosition) => void;

};

export const useLoginStore = create<LoginStore>((set) => ({
    isUserLoggedIn: false,
    setIsUserLoggedIn: (flag) => set(() => ({
        isUserLoggedIn: flag,
    })),

    loginPosition: ELoginPosition.LOGIN_PAGE,
    setLoginPosition: (position) => set(() => ({
        loginPosition: position,
    })),
}));