import React, { FC, ReactNode } from 'react';
import useAnalytics from 'modules/stores/analytics/useAnalytics';
import Link from 'components/link/Link';
import { usePathname } from 'next/navigation';
import { useLoginStore } from 'modules/stores/login/useLoginStore';
import { getAddAdvertisementEvent } from 'modules/gtmEvents/helpers/events/common/headerEventsHelper';
import { PathNameToActionRecord } from 'modules/gtmEvents/records/header/PathNameToActionRecord';
import { EAction } from 'modules/gtmEvents/enums/header/EAction';
import {getResetEcommerceEvent} from 'modules/gtmEvents/helpers/events/common/commonEventsHelper';
import {IGtmEvent} from 'modules/analytics/IAnalytics';
export interface IWrapper {
    href: string;
    children: ReactNode | ReactNode[];
}

export const Wrapper: FC<IWrapper> = ({ href, children }) => {
    const pathname = usePathname();
    const {
        gtm: { sendEvent: gtmSendEvent },
    } = useAnalytics();

    const isUserLoggedIn = useLoginStore((store) => store.isUserLoggedIn);
    const action: EAction = PathNameToActionRecord[pathname || ''];

    const handleOnClick = () => {
        const events: IGtmEvent[] = [
            getResetEcommerceEvent(),
            getAddAdvertisementEvent(isUserLoggedIn, action),
        ];

        gtmSendEvent(...events);
    };

    return (
        <Link onClick={handleOnClick} noUnderline href={href} ariaLabel='Add advertiser'>
            {children}
        </Link>
    );
};
