import { useState, FC, ReactNode, MouseEventHandler, Children } from 'react';
import {Grid, Menu, MenuItem, Theme, Box, Palette} from '@mui/material';
import BaseLink from 'components/link/Link';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useOpenMenuState from 'modules/stores/layout/useOpenMenuState';
import {Text} from 'modules/theme/components/text/Text';


interface IProps {
    name: string;
    path?: string;
    children?: ReactNode;
    showBullet?: boolean;
    inverseColor?: boolean;
    onClick?: () => void;
}

const Link:FC<IProps> = ({ name, path, showBullet = true, onClick, children, inverseColor }) => {
    const [anchorElement, setAnchorElement] = useState<HTMLDivElement|null>(null);
    const {
        open: openMenu,
        close: closeMenu,
        isOpen: isMenuOpen
    } = useOpenMenuState();

    const menuKey = `footer-menu-item-${name}`;

    const handleOnClick: MouseEventHandler<HTMLDivElement> = (event) => {
        if (!path) {
            event.preventDefault();
        }

        setAnchorElement(event.currentTarget);
        openMenu(menuKey);
        onClick && onClick();
    };

    return <>
        <Grid item xs={6} sm={4}>
            <Grid container alignItems='center' spacing={1}>
                {showBullet && (
                    <Grid item xs={1} sm="auto">
                        <Box position="relative" top="-1" mr={1}>
                            <Text variant="label2" color={(palette: Palette) => palette.labelSecondaryInverse}>
                                •
                            </Text>
                        </Box>
                    </Grid>
                )}
                <Grid item xs={showBullet ? 11 : 12} sm="auto">
                    <Box sx={{
                        position: 'relative',
                        display: 'inline-block'
                    }} onClick={handleOnClick}>
                        <BaseLink href={path || '#'}>
                            <Text variant="label2" inverseColor={inverseColor}>{name}</Text>
                        </BaseLink>
                        <Box sx={{
                            position: 'absolute',
                            right: -15,
                            top: -3,
                            cursor: 'pointer'
                        }}>
                            {children && (
                                <Text variant="label2" color={(palette: Palette) => palette.labelSecondaryInverse}>
                                    <ExpandMoreIcon sx={{
                                        fontSize: 'inherit',
                                        position: 'relative',
                                        top: 5
                                    }} />
                                </Text>
                            )}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
        {children && (
            <Menu
                id={`footer-item-dropdown-${name}`}
                open={anchorElement !== null && isMenuOpen(menuKey)}
                onClose={() => { closeMenu(menuKey); }}
                anchorEl={anchorElement}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: -10,
                    horizontal: 0
                }}
                PaperProps={{
                    sx: {
                        border: (theme: Theme) => `1px solid ${theme.palette.labelPrimary.main}`
                    }
                }}
            >
                {Children.map(children, child => (
                    <MenuItem>
                        {child}
                    </MenuItem>
                ))}
            </Menu>
        )}
    </>;
};

export default Link;
