import { FC } from 'react';
import { useMediaQuery, Theme, ClickAwayListener } from '@mui/material';
import CloseIcon from 'modules/theme/components/icons/basic/Close.svg';
import AlertIcon from 'components/updateBrowserModal/img/svg/alert.svg';
import EdgeBrowserIcon from 'components/updateBrowserModal/img/svg/edge-browser-icon.svg';
import FirefoxBrowserIcon from 'components/updateBrowserModal/img/svg/firefox-browser-icon.svg';
import GoogleChromeBrowserIcon from 'components/updateBrowserModal/img/svg/google-chrome-browser-icon.svg';
import OperaBrowserIcon from 'components/updateBrowserModal/img/svg/opera-browser-icon.svg';

interface IProps {
    onClose?: () => void;
}

export const UpdateBrowserModalContent: FC<IProps> = ({ onClose }) => {
    const isDesktop = useMediaQuery((theme: Theme) =>
        theme.breakpoints.up('md')
    );

    return (
        <ClickAwayListener
            width={500}
            onClickAway={() => onClose()}
            touchEvent={false}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    position: 'relative',
                }}
            >
                <div
                    onClick={onClose}
                    style={{
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        width: '100%',
                    }}
                >
                    <CloseIcon width="24" height="24" />
                </div>
                <div>
                    <AlertIcon width="40" height="40" className="alert-icon" />
                </div>
                <p
                    style={{
                        fontSize: isDesktop ? '24px' : '20px',
                        fontWeight: '600',
                        margin: 0,
                        padding: 0,
                        color: '#2D2926',
                    }}
                >
                    Aktualizujte svoj prehliadač
                </p>
                <p
                    style={{
                        fontSize: '14px',
                        textAlign: 'center',
                        lineHeight: '1.4',
                        marginTop: '8px',
                        color: '#707372',
                    }}
                >
                    Pre plnohodnotné používanie Nehnuteľnosti.sk, prosím,
                    aktualizujte svoj internetový prehliadač.
                </p>
                <div
                    style={{
                        display: 'flex',
                        gap: isDesktop ? '2rem' : '1rem',
                        marginTop: '16px',
                    }}
                >
                    <a
                        href="https://www.microsoft.com/edge"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            textDecoration: 'none',
                            color: 'inherit',
                        }}
                    >
                        <EdgeBrowserIcon
                            style={{
                                width: isDesktop ? '60px' : '40px',
                                height: isDesktop ? '60px' : '40px',
                            }}
                        />
                        <p style={{ fontSize: '0.875rem' }}>Edge</p>
                    </a>
                    <a
                        href="https://www.mozilla.org/firefox"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            textDecoration: 'none',
                            color: 'inherit',
                        }}
                    >
                        <FirefoxBrowserIcon
                            style={{
                                width: isDesktop ? '60px' : '40px',
                                height: isDesktop ? '60px' : '40px',
                            }}
                        />
                        <p style={{ fontSize: '0.875rem' }}>Firefox</p>
                    </a>
                    <a
                        href="https://www.google.com/chrome"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            textDecoration: 'none',
                            color: 'inherit',
                        }}
                    >
                        <GoogleChromeBrowserIcon
                            style={{
                                width: isDesktop ? '60px' : '40px',
                                height: isDesktop ? '60px' : '40px',
                            }}
                        />
                        <p style={{ fontSize: '0.875rem' }}>Chrome</p>
                    </a>
                    <a
                        href="https://www.opera.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            textDecoration: 'none',
                            color: 'inherit',
                        }}
                    >
                        <OperaBrowserIcon
                            style={{
                                width: isDesktop ? '60px' : '40px',
                                height: isDesktop ? '60px' : '40px',
                            }}
                        />
                        <p style={{ fontSize: '0.875rem' }}>Opera</p>
                    </a>
                </div>
            </div>
        </ClickAwayListener>
    );
};
