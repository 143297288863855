import { usePathname } from 'next/navigation';
import { Stack, useTheme } from '@mui/material';
import { IGtmEvent } from 'modules/analytics/IAnalytics';
import { Text } from 'modules/theme/components/text/Text';
import React, { FC, useMemo, useRef, useState } from 'react';
import { useLoginStore } from 'modules/stores/login/useLoginStore';
import useAnalytics from 'modules/stores/analytics/useAnalytics';
import { getLoginEvent } from 'modules/gtmEvents/helpers/events/common/headerEventsHelper';
import { PathNameToActionRecord } from 'modules/gtmEvents/records/header/PathNameToActionRecord';
import { getResetEcommerceEvent } from 'modules/gtmEvents/helpers/events/common/commonEventsHelper';
import { Popover } from 'components/layout/header/components/navbar/common/links/user/loggedOut/desktop/popover/Popover';
import { LoginIcon } from 'components/layout/header/components/navbar/common/links/user/loggedOut/desktop/icon/LoginIcon';
import { Content } from 'components/layout/header/components/navbar/common/links/user/loggedOut/partials/content/Content';

interface IAddAdvertiser {
    text: string;
}

export const Login: FC<IAddAdvertiser> = ({ text }) => {
    const { palette } = useTheme();
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const pathname = usePathname();
    const { gtm: { sendEvent: gtmSendEvent }} = useAnalytics();
    const isUserLoggedIn = useLoginStore((store) => store.isUserLoggedIn);

    const action = useMemo(() => PathNameToActionRecord[pathname], [pathname]);

    const handleClick = () => {
        if (!open) {
            const events: IGtmEvent[] = [
                getResetEcommerceEvent(),
                getLoginEvent(isUserLoggedIn, action),
            ];

            gtmSendEvent(...events);
        }

        setOpen(!open);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Stack
                ref={anchorRef}
                sx={{
                    '&:hover': {
                        bgcolor: palette.backgroundSecondary.main,
                    },
                    '&:active': {
                        bgcolor: palette.backgroundSecondary.main,
                        outline: `2px solid ${palette.backgroundPrimaryInverse.main}`,
                    },
                }}
                gap={0.75}
                paddingY={0.75}
                direction="row"
                paddingLeft={1}
                paddingRight={2}
                borderRadius={10}
                alignItems="center"
                justifyContent="center"
                onClick={handleClick}
            >
                <LoginIcon />
                <Text variant="label1">{text}</Text>
            </Stack>
            <Popover
                anchorEl={anchorRef?.current}
                isOpen={open}
                onClose={handleClose}
                offsetOptions={{
                    x: 0,
                    y: 14,
                }}
            >
                <Content onClose={handleClose} />
            </Popover>
        </>
    );
};
